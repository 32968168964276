import { withStore } from "../libs/mobx/withStore";
import s from "./404.module.scss";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Footer } from "~components/Footer";
import { Navbar } from "~components/Navbar";
import { TextBlock, Seo } from "~components/blocks";
import { Section, Text, ArrowButton } from "~components/shared";

export const NotFoundPage = () => (
  <StaticQuery
    query={NotFoundQuery}
    render={(data) => {
      const {
        notFoundOptions: {
          notFoundLinks: links,
          notFoundContactLinks: contact_links,
          notFoundSubtitle: subtitle,
          notFoundText: text,
          notFoundTextBackgroudColor: backgroundColor,
          notFoundTextTitle: textTitle,
          notFoundTitle: title,
        },
        contactOptions: options,
        id,
      } = data.wp.themeGeneralSettings;

      const contactLinks = contact_links?.map((link) => ({
        icon: link.notFoundContactLinksIcon,
        link: link.notFoundContactLinksLink,
        title: link.notFoundContactLinksTitle,
      }));

      return (
        <>
          <Seo />
          <Navbar />
          <Section slimTop>
            <div className={`${s.wrapper} row`}>
              <div className="col-12 col-sm-6 offset-sm-3">
                <Text h1 border="narrow">
                  {title}
                </Text>
                <div className={s.subtitle}>
                  <Text p>{subtitle}</Text>
                </div>
                {links &&
                  links.map((link) => (
                    <div className={s.link} key={link.notFoundLink.id}>
                      <ArrowButton
                        direction="forward"
                        href={link.notFoundLink.uri}
                        bold
                      >
                        {link.notFoundLinkTitle}
                      </ArrowButton>
                    </div>
                  ))}
              </div>
            </div>
          </Section>
          <TextBlock
            id={id}
            title={textTitle}
            text={text}
            topOfPage={["no"]}
            links={contactLinks}
            backgroundColor={backgroundColor}
            titleWeight="h1"
          />
          <div className={s.footer}>
            <Footer {...options} />
          </div>
        </>
      );
    }}
  />
);

export default withStore(NotFoundPage);

const NotFoundQuery = graphql`
  query {
    wp {
      themeGeneralSettings {
        id: __typename
        notFoundOptions {
          notFoundLinks {
            notFoundLink {
              id: __typename
              ... on WpPage {
                uri
              }
            }
            notFoundLinkTitle
          }
          notFoundContactLinks {
            notFoundContactLinksIcon
            notFoundContactLinksLink
            notFoundContactLinksTitle
          }
          notFoundSubtitle
          notFoundText
          notFoundTextBackgroudColor
          notFoundTextTitle
          notFoundTitle
        }
        general {
          logotypeHeaderDark {
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        contactOptions {
          address
          addressTitle
          contactTitle
          email
          fieldGroupName
          phone
          socialMediaTitle
          socialMedia {
            fieldGroupName
            link
            platform
          }
        }
      }
      generalSettings {
        url
      }
    }
  }
`;
